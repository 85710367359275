<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>

<script>
import { GET_CULTURA_ID } from "@/apollo/queries";
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
  components: {
    SectionTabs,
  },
  data() {
    return {
      title: "Cultura",
      tab_component:[
              { name: "Lista", route: "culture-list", },
              { name: "Crear", route: "culture-add", },
              { name: "Valor", route: "culture-valor-add", }
            ],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
    };
  },
  mounted() {
    this.getCultura();
  },
  methods: {
    getCultura() {
      this.$apollo
        .query({
          query: GET_CULTURA_ID,
          variables: {
            id_empresa: this.id_empresa, //id segun la empresa, cuando este ok el login
          },
        })
        .then((res) => {
          var resp = res.data.culturas;
          if (resp.id_cultura) {
            this.tab_component = [
              { name: "Lista", route: "culture-list", },
              { name: "Crear/Editar", route: "culture-add", },
              { name: "Valor", route: "culture-valor-add", }
            ];
          }
        })
        .catch(() => {
          console.log("");
        });
    },
  },
};
</script>